.toaster-base{
    opacity:1;
}

.toaster-on{
    opacity:0;
    transition:opacity 1s;
    transition-delay:2s;
}

.toaster-off{
    opacity:1;
    transition:opacity 0s;
    transition-delay:0s;
}