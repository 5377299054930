.form-input-error-message,
.warn {
  color: #f44336;
}

.select-child {
  margin: 0 0 20px;
  overflow-x: auto;
  overflow-y: hidden;
  transition: all 0.3s ease-in-out;
  vertical-align: top;
  display: flex;
}
.select-child .child {
  list-style-type: none;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  position: relative;
  height: 54px;
  color: #e0dfe4;
}
.select-child .child.active {
  color: inherit;
}
.select-child .child:not(:nth-child(1)) {
  margin: 0 10px;
}
.select-child .child:nth-child(1) {
  margin: 0 10px 0 0;
}
.select-child .child .avatar {
  position: absolute;
}
.select-child .child span {
  padding-left: 65px;
  text-align: left;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  height: 100%;
}
.select-child .child.active .avatar {
  border: 2px solid #4fdfc6;
}
.select-child .child .avatar {
  border: 2px dashed #e0dfe4;
}

.avatar {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  border-radius: 50px;
  box-sizing: content-box;
  border: solid 2px white;
  background-size: cover;
  background-position: center;
  background-color: #f6f6f6;
}

.select-hours-slider {
  margin: 60px 20px 25px;
}

.date-picker {
  border-radius: 7px;
  box-shadow: lightgrey 0 0 5px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.date-picker .DayPicker {
  margin: auto;
}

@media screen and (max-width: 380px) {
  .date-picker {
    margin-left: -20px;
    margin-right: -20px;
    box-shadow: none;
  }
}

.search form .select,
.search form .select-child,
.search form .date-picker {
  margin-top: 20px;
  margin-bottom: 20px;
}
