.portletNav{
    color:rgba(128,128,128,0.4);
}

.portletTitle{
    font-size: 130%;
}

.portletIcon{
    margin:0px 0px 0px 10px;
    font-size: 20px;
    position: relative;
    top:2px;
    color:rgba(128,128,128,0.4);
}

.portletNav a{
    color:rgba(128,128,128,0.4);
}

.subheader{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px 25px;
}
.subheader-main{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
}
.subheader-title{
    margin: 0;
    padding: 0 1.5rem 0 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: #434349;
}
.subheader-toolbar{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
}