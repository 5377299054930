@font-face {
	font-family: 'TungstenNarrow-Semibold';
	src: url('../../css/fonts/TungstenNarrow-Semibold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'TungstenNarrow-Semibold';
	src: url('../../css/fonts/TungstenNarrow-Semibold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins-ExtraBold';
	src: url('../../css/fonts/Poppins-ExtraBold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Poppins-Regular';
	src: url('../../css/fonts/Poppins-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.towers-title {
	color: white;

	margin: 4px 4px 64px 4px;

	font-family: 'TungstenNarrow-Semibold';
	font-style: normal;
	font-weight: 373;
	font-size: 40px;
	line-height: 32px;
	text-transform: uppercase;
	color: #ffffff;

	text-align: center;

	letter-spacing: 0.02em;
}

.tower-explorerImgContainer {
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-family: 'TungstenNarrow-Semibold';
	font-style: normal;
	font-weight: 373;
	font-size: 40px;
	line-height: 32px;
	text-transform: uppercase;
	color: #ffffff;

	text-align: center;

	letter-spacing: 0.02em;
}

.tower-explorerImg {
	aspect-ratio: 742/309;
	width: 100%;
	max-width: 800px;

	background-image: url(../../css/assets/towerList.png);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.tower-explorerImg-Part {
	display: block;
	width: 25%;
	height: 100%;
}

.magnicity-welcome {
    height: 20%;

    top: 10%;

    margin: 0 0 0 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.magnicity-welcome-text {
	margin: 4px;

	font-family: 'TungstenNarrow-Semibold';
	font-style: normal;
	font-weight: 373;
	font-size: 40px;
	line-height: 32px;
	text-transform: uppercase;
	color: #ffffff;
}

.introScreen-langSelectionTitle {
    font-family: 'Poppins-Regular';
    font-size: clamp(0.9em, 50%, 1.2em);
    font-weight: 400;

    margin: 0 0 0 0;

    text-align: center;
    color: white;
}