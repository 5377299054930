.calendarTimelineContainer{
    width:calc(100% - 230px); //Meme taille que .calendarTimeline tr td:first-child
    margin-left: 230px; //Meme taille que .calendarTimeline tr td:first-child
    overflow-x: auto;
    overflow-y: visible;
}

.calendarTimelineContainerPending{
    width:calc(100% - 230px); //Meme taille que .calendarTimeline tr td:first-child
    margin-left: 230px; //Meme taille que .calendarTimeline tr td:first-child
    overflow-y: visible;
}

.calendarTimeline{
    width:100%; //Modif ici de la largeur du tableau (avec overflow)
    border-collapse: collapse;
}

.calendarTimeline tr td:first-child{
    position:absolute;
    width: 230px; //Meme taille que .calendarTimelineContainer
    left:0px;
    margin-left:10px;
    border-right: 1px solid #e6e6f9;
    border-top: 1px solid #e6e6f9;
    border-top-width: 1px;
    margin-top: -1px;
}

.calendarTimelineGroup td:first-child{
    color:white;
    padding:10px 20px;
}

.calendarTimelineEntry{
    vertical-align: middle;
    display:table;
    height: 50px; //Meme height que dans .calendarTimeline tr
}

.calendarTimeline tr{
    border-bottom: 1px solid #e6e6f9;
    height:50px; //Meme height que dans .calendarTimelineEntry
}

.calendarTimelineEntry .avatar{
    display:inline-block;
    margin-left: 15px !important;
    margin-top: 7px !important;
    border: 1px solid;
    height: 35px;
    width: 35px;
}

.calendarTimelineEntry .avatarhidden{
    visibility:hidden;
    width:1px;
    margin-left: 0px !important;
}

.calendarTimelineEntry .avatarRight{
    float:right;
    margin-right: 15px !important;
}

.calendarTimelineEntry p{
    display:inline-block;
    position: relative;
    top: -15px;
    margin-left: 15px;
}

.calendarTimelineTitle{
    text-align:center;
}

.calendarTimelineTitle td{
    text-align:center;
    position: relative;
}

.calendarTimelineGroup td:first-child, .calendarTimelineGroup {
    height:40px !important;
}

.calendarTimelineBarWrapper{
    position:relative;
    height:18px;
}

.calendarTimelineBar{
    position:absolute;
    height:100%;
    border-radius:9px;
    color:white;
    text-align:center;
}

// .calendarTimelineBar:hover{
//     cursor:pointer;
// }

.calendarTimelineBar-present{
	background-color:#6888f8;
	top:50%;
	height: 50%;
}

// .calendarTimelineBar-present:hover{
//     background-color:#c6d2fc;
// }

.calendarTimelineBar-absence{
    background-color:#e75b44;
}

.calendarTimelineBar-absence.calendarTimelineBar-disponibility{
    background-color:#ffc4c4;
}

.calendarTimelineBar-disponibility{
    background-color:#58d8a4;
}

.calendarTimelineBar-disponibility:hover{
    background-color:#dcf7ec;
}

.calendarTimelineBar-striped{
    background-color:#6888f8;
    background-image: linear-gradient(45deg,
    rgba(255,255,255,.8) 40%,
    rgba(255,255,255,.3) 40%,
    rgba(255,255,255,.3) 50%,
    rgba(255,255,255,.8) 50%,
    rgba(255,255,255,.8) 92%,
    rgba(255,255,255,.3) 92%,
    rgba(255,255,255,.3));
    background-size: .5rem .5rem;
}

// .calendarTimelineBar-striped:hover{
//     background-color:#c6d2fc;
// }

.calendarTimelineBar-hover:hover{
    background-color:#c6d2fc;
    cursor:pointer;
}

.calendarTimelineBar-absence-hover:hover{
    background-color:#ff8c7a;
    cursor:pointer;
}

.calendarTimeline .topLeftTitle{
    height: 50px;
    vertical-align: bottom;
    display: inline-block;
    padding: 15px;
}

.calendarTimelineGroupTitle{
    float:left;
    font-weight: bold;
}

.calendarTimelineGroupChevron{
    float:right;
    cursor:pointer;
    padding:0px 10px;
}

.calendarTimelineGroupChevron:hover{
    color: #333;
    background-color:#eee;
    border-radius:10px;
  }

.calendarTimelineHourLine{
    position:absolute;
    background-color:#e6e6f9;
    height:15px;
    width:1px;
    left:50%;
}

.calendarTimelineEntryLink{
    position:absolute;
    top:0px;
    bottom:0px;
    left:0px;
    right:0px;
}

a .avatar{
    cursor:pointer !important;
}

.react-contextmenu{
    border:1px solid #ddd;
    border-radius:7px;
    color:black;
    background-color:white;
    z-index:100;

}

.react-contextmenu-item{
    padding:5px;
    border-radius:7px;
}

.react-contextmenu-item:hover{
    background-color:#ddd;
}

.well{
    visibility: hidden;
}
