.blockDataTableEdit{
    overflow: visible;
}

.dataTableEdit{
    overflow: visible;
}

.dataTableEdit .datatable-row .datatable-cell{
    overflow: visible;
}

.dataTableEdit .datatable-row .datatable-cell span{
    display: block;
    overflow: visible;
}

.dataTableEdit .datatable-row .datatable-cell:first-child span{
    margin-right:10px;
}

.dataTableEdit .datatable-row .datatable-cell:last-child span{
    margin-left:10px;
}