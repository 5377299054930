#root{
	height: 100%;
}

body{
	background-color: white;
}

.appstore_cover{
	display: block;
	width:160px;
	height:160px;
	background-image: url("./app.png");
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	margin-bottom: 10px;
}

.appstore_link, .appstore_link:visited, .appstore_link:hover, .appstore_link:active{
	display:block;
	width:160px;
	height:54px;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	margin-bottom: 10px;
	color:rgba(0,0,0,0)
}

.appstore_link_apple{
	background-image: url("./icon_appstore.png");
}

.appstore_link_android{
	background-image: url("./icon_playstore.png");
}