.navbarFooter{
    width: 100%;
    z-index: -1;

    background-color: white;
    border-top:1px solid #CCCCCC;
}

.navbarFooter a{
    color:#666666;
    margin: 15px;
    font-size: 15px;
}

.navbarFooter .footerRight{
    margin:0px;
    width: 100%;
}

@media (min-width: 1200px){
    .navbarFooter .footerRight {
        max-width: 100%;
    }
}

@media (max-width: 1024px){
    .navbarFooter .footerRight {
        max-width: 100%;
        text-align: center;
    }
    .navbarFooter .footerRight .navbar-nav:last-child{
        width: 100%;
        flex-direction: column;
    }
}

@media (min-width: 768px){
    .navbarFooter .footerRight {
        max-width: 100%;
    }
}

@media (min-width: 576px){
    .navbarFooter .footerRight {
        max-width: 100%;
    }
}