.btn-radio {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    text-align: left;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 1rem;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.btn-radio-inline .btn-radio {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 5px;
}
.btn-radio>input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.btn-radio>span {
    background: none;
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 50% !important;
    border: 1px solid #d1d7e2;
}
.btn-radio>input:checked ~ span:after {
    display: block;
}
.btn-radio>span:after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    margin-left: -4px;
    margin-top: -4px;
    width: 8px;
    height: 8px;
    border-radius: 100% !important;
    border: solid #5A80FB;
    background: #5A80FB;
}

.form-radio{
    margin-top:1rem;
    margin-bottom:1rem;
}

.form-radio .radio{
    margin-bottom: 0rem;
}

.form-radio .radio input{
    float: left;
    margin-right:5px;
}