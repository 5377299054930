.datatable-cell-0 span{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 0px;
}

.datatable-cell-0{
    padding: 16px 10px;
    vertical-align: middle;
    font-size: 1rem;
}

.datatable-cell-0 .user{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0;
    margin: 0;
}
.datatable-cell-0 .user-pic{
    padding-right: 0.5rem;
}
.datatable-cell-0 .user-pic img{
    border-radius: 50%;
    max-width: 40px;
    max-height: 40px;
}
.datatable-cell-0 .user-details {
    line-height: 0;
}
.datatable-cell-0 .user-firstname {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
    color: #646c9a;
}
.datatable-cell-0 .user-lastname {
    display: inline-block;
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 400;
    color: #a1a8c3;
}
.datatable-cell-0 .badge {
    margin: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.8rem;
    color: #fff;
    background: #00D85A;
    height: auto;
    width: fit-content;
    padding: 5px 15px;
    border-radius: 2rem;
    font-weight: 300;
}
.datatable-cell-0 .dropdown{
    display: inline-block;
}