.tile{
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(82,63,105,0.2);
    box-shadow: 0px 0px 13px 0px rgba(82,63,105,0.2);
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 0;
}
.tile-header{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    padding: 0 25px;
    min-height: 60px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.tile-header-label{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: flex-first;
    align-content: flex-first;
}
.tile-header-title{
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: #3d4465;
}
.tile-header-border{
    border-bottom: 1px solid #ebedf2;
}
.tile-header-toolbar{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: end;
    align-content: flex-end;
}
.tile-header-toolbarFull{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: end;
    width: 100%;
}
.tile-body, .tile .tab-content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 25px;
    border-radius: 4px;
}
.tile .tab-content{
    padding: 0px;
}
.tile-footer{
    padding: 25px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.tile-col{
    padding-left:15px;
    padding-right:15px;
}

.tile .nav-tabs .nav-link{
    border-top:0px;
    font-size: 1.2rem;
    font-weight: 500;
    color: #3d4465;
    padding: 15px 25px;
    border-top-left-radius:0px;
    border-top-right-radius:0px;
}

.tile .nav-tabs .nav-link:first-child{
    border-left:0px;
    border-top-left-radius:4px;
}

.tile .nav-tabs .nav-link .disabled{
    color:#c2c2c2 !important;
    opacity: 0.5 !important;
}

