@import '../../css/gj-strap-color.scss';
@import '../../../../css/stylesheets/gj-strap-custom.scss';

.rc-slider-tooltip{
    z-index:500000;
}

.rc-slider-handle{
    width: 30px;
    height: 30px;
    margin-top: -15px;
    border: none;
    background-color: $form-slider-color;
}

.rc-slider-track{
    display: none;
}